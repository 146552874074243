<template>
  <Layout>
    <div class="partcontainer regcontainer">
      <Header
        :title="content.v_title"
        :back="true"
        regulation="tcf20"
      />
      <h1 :dir="dir">
        {{ content.v_title }}
      </h1>
      <div
        class="text"
        :dir="dir"
        v-html="content.v_content"
      />
      <div
        class="list"
        :dir="dir"
      >
        <div class="list_item">
          <div class="list_handle">
            <div
              :class="['list_expand', iabExpanded === true ? 'expanded' : '']"
              @touchend="expand($event, 'iab')"
              @click="expand($event, 'iab')"
            >
              >
            </div>
            <div class="list_title">
              {{ content.v_iab }}
            </div>
            <div
              v-if="!$compliance.legacyMode"
              :class="['list_checkbox', iabConsented === true ? 'checked' : '']"
              @touchend="toggleConsent($event, 'iab')"
              @click="toggleConsent($event, 'iab')"
            >
              cb_iabvendors
            </div>
          </div>
          <div :class="['list_expanded', iabExpanded === true ? 'expand' : '']">
            <Loading v-if="iabVendors === null" />
            <Vendor
              v-for="(vendor, i) in vendorsBatch"
              :key="i"
              :vendor="vendor"
              :vendor-details="true"
              @consented="updateIABConsent"
            />
            <div
              v-if="vendorsBatch !== null && showMore"
              class="list_more"
              @touchend="showMoreVendors($event)"
              @click="showMoreVendors($event)"
            >
              {{ content.a_showmore }}
            </div>
          </div>
        </div>
        <div class="list_item">
          <div class="list_handle">
            <div
              :class="[
                'list_expand',
                nonIabExpanded === true ? 'expanded' : '',
              ]"
              @touchend="expand($event, 'noniab')"
              @click="expand($event, 'noniab')"
            >
              >
            </div>
            <div class="list_title">
              {{ content.v_noniab }}
            </div>
            <div
              v-if="!$compliance.legacyMode"
              :class="[
                'list_checkbox',
                nonIabConsented === true ? 'checked' : '',
              ]"
              @touchend="toggleConsent($event, 'noniab')"
              @click="toggleConsent($event, 'noniab')"
            >
              cb_vendors
            </div>
          </div>
          <div
            :class="['list_expanded', nonIabExpanded === true ? 'expand' : '']"
          >
            <Loading v-if="nonIabVendors === null" />
            <Vendor
              v-for="(vendor, i) in nonIabVendors"
              :key="i"
              :vendor="vendor"
              :vendor-details="true"
              :non-i-a-b="true"
              @consented="updateNonIABConsent"
            />
          </div>
        </div>
      </div>
      <Footer screen="partners" />
    </div>
  </Layout>
</template>

<script>
import * as Sentry from '@sentry/vue'
import Header from '@components/Header.vue'
import Vendor from '@components/tcf20/Vendor.vue'
import Footer from '@components/tcf20/Footer.vue'
import Loading from '@components/Loading.vue'

export default {
  metaInfo() {
    return {
      title: 'Vendors | Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Vendors | Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Header,
    Vendor,
    Footer,
    Loading,
  },
  data() {
    return {
      content: {},
      showVendorsBatchSize: 10,
      totalVendorsToShow: 10,
      iabExpanded: false,
      nonIabExpanded: false,
      iabConsented: false,
      nonIabConsented: false,
      iabVendors: null,
      nonIabVendors: null,
      startTime: null,
      endTime: null,
      showMore: true,
      dir: 'ltr',
    }
  },
  computed: {
    vendorsBatch() {
      return this.iabVendors !== null
        ? this.iabVendors.slice(0, this.totalVendorsToShow)
        : null
    },
  },
  created() {
    // get screen display data
    this.content = this.$compliance.getLocalisedContent()
  },
  async mounted() {
    this.iabConsented = await this.$compliance.TCF20.hasConsented()
    this.nonIabConsented = this.$compliance.nonIAB.hasConsented()
    this.$compliance.TCF20.setCurrentConsentScreen('custom')
    this.dir = this.$compliance.dir
  },
  methods: {
    async updateNonIABConsent() {
      this.nonIabConsented = await this.$compliance.nonIAB.hasConsented()
    },
    updateIABConsent() {
      this.iabConsented = this.$compliance.TCF20.hasConsented()
    },
    retrieveIABVendors(callback) {
      if (this.iabVendors === null) {
        // fetch vendors
        this.startTime = new Date().getTime()
        if (this.$compliance.legacyMode) {
          const v = this.$compliance.GVL.getVendors('all')
          this.iabVendors = Object.values(v).map((el) => {
            return { ...el }
          })
          callback()
        } else {
          this.$compliance.delegate
            .promiseAllVendors()
            .then((res) => {
              this.iabVendors = Object.values(res).map((el) => {
                return {
                  ...el,
                  consent: this.$compliance.TCF20.getVendorConsent(el.id),
                }
              })
              callback()
            })
            .catch((err) => {
              this.$compliance.debug.error(err)
            })
        }
      }
    },
    retrieveNIABVendors() {
      if (this.nonIabVendors === null) {
        const nv = this.$compliance.nonIAB.getVendors()
        if (this.$compliance.legacyMode) {
          this.nonIabVendors = nv
        } else {
          this.nonIabVendors = Object.values(nv).map((el) => {
            return {
              ...el,
              consent: this.$compliance.nonIAB.getVendorConsent(el.id),
            }
          })
        }
      }
    },
    registerIABConsent() {
      if (this.iabConsented) {
        this.$compliance.TCF20.setVendorConsent(
          Object.values(this.iabVendors).map((v) => v.id)
        )
        if (!this.$compliance.legacyMode) {
          this.iabVendors = Object.values(this.iabVendors).map((el) => {
            return { ...el, consent: true }
          })
        }
      } else {
        this.$compliance.TCF20.unsetVendorConsent(
          Object.values(this.iabVendors).map((v) => v.id)
        )
        if (!this.$compliance.legacyMode) {
          this.iabVendors = Object.values(this.iabVendors).map((el) => {
            return { ...el, consent: false }
          })
        }
      }
    },
    expand(event, type) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      if (type === 'iab') {
        this.iabExpanded = !this.iabExpanded
        // retrieve & replace screen option data from GVL & set initial consents
        this.retrieveIABVendors(() => {
          var inter = setInterval(() => {
            if (this.iabVendors !== null) {
              this.endTime = new Date().getTime()
              this.$compliance.debug.log(
                'INFO',
                'Vendor list loaded in ' +
                  (this.endTime - this.startTime) +
                  'ms'
              )
              clearInterval(inter)
            }
          }, 80)
        })
      }
      if (type === 'noniab') {
        this.nonIabExpanded = !this.nonIabExpanded
        // retrieve & replace screen option data from GVL & set initial consents
        this.retrieveNIABVendors()
      }
      Sentry.addBreadcrumb({
        type: 'user',
        category: 'ui.click',
        message: '[TCF2.0] expand ' + type + ' vendors',
        timestamp: new Date(),
      })
    },
    toggleConsent(event, type) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      if (type === 'iab') {
        this.iabConsented = !this.iabConsented
        // first fetch vendors if we haven't yet
        // registerIABConsent on callback
        if (this.iabVendors === null)
          this.retrieveIABVendors(() => {
            this.endTime = new Date().getTime()
            console.log(this.endTime - this.startTime + 'ms')
            this.registerIABConsent()
          })
        else this.registerIABConsent()
      } else if (type === 'noniab') {
        this.nonIabConsented = !this.nonIabConsented
        if (this.nonIabVendors === null) this.retrieveNIABVendors() // first fetch vendors if we haven't yet

        if (this.nonIabConsented) {
          this.$compliance.nonIAB.setVendorConsent('all')
          if (!this.$compliance.legacyMode) {
            this.nonIabVendors = Object.values(this.nonIabVendors).map((el) => {
              return { ...el, consent: true }
            })
          }
        } else {
          this.$compliance.nonIAB.unsetVendorConsent('all')
          if (!this.$compliance.legacyMode) {
            this.nonIabVendors = Object.values(this.nonIabVendors).map((el) => {
              return { ...el, consent: false }
            })
          }
        }
      }
      Sentry.addBreadcrumb({
        type: 'user',
        category: 'ui.click',
        message: '[TCF2.0] toggle ' + type + ' consent',
        timestamp: new Date(),
      })
    },
    showMoreVendors(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.totalVendorsToShow += this.showVendorsBatchSize
      if (this.totalVendorsToShow >= this.iabVendors.length)
        this.showMore = false
      Sentry.addBreadcrumb({
        type: 'user',
        category: 'ui.click',
        message: '[TCF2.0] show more vendors',
        timestamp: new Date(),
      })
    },
  },
}
</script>

<style lang="scss">
.partcontainer {
  position: relative;
  background: #fff;
  padding: 10px 0 10px 0;
  width: 100%;

  .text {
    color: #4c5d6a;
    font-size: 1em;
    text-align: center;
    margin: 0;
    padding: 20px 10px 0 10px;
    span {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
  h1 {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 70px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .list {
    background: #fff;
    padding: 0 10px;
    margin: 20px 0 130px 0;
    @media screen and (min-width: 768px) {
      margin: 20px 0 200px 0;
    }

    h2 {
      font-size: 1.6em;
      color: #626d77;
      border-bottom: 1px solid #b8b8b8;
    }
    .list_item {
      background: #fff;
      margin: 0 0 8px 0;
      color: #4c5d6a;

      .list_handle {
        display: flex;
        align-items: center;

        .list_expand {
          position: relative;
          overflow: hidden;
          background-image: url('../../../assets/images/btn_expand.png');
          background-size: contain;
          background-repeat: no-repeat;
          text-indent: -9999px;
          width: 40px;
          height: 40px;
          flex-basis: 40px;
          justify-self: start;
          transform: rotate(-90deg);
          transition: transform 0.5s;
          &.expanded {
            transform: rotate(0);
          }
          @media screen and (min-width: 768px) {
            width: 55px;
            height: 55px;
            flex-basis: 55px;
          }
        }
        .list_title {
          font-size: 1.2em;
          line-height: 1.2em;
          align-self: center;
          flex-basis: calc(100% - 70px);
          @media screen and (min-width: 768px) {
            font-size: 1.4rem;
          }
          @media screen and (min-width: 1024px) {
            font-size: 1.8rem;
          }
        }
        .list_checkbox {
          background-image: url('../../../assets/images/btn_checkbox_empty.png');
          background-size: contain;
          background-repeat: no-repeat;
          text-indent: -9999px;
          width: 40px;
          height: 30px;
          flex-basis: 40px;
          &.checked {
            background-image: url('../../../assets/images/btn_checkbox.png');
            background-size: contain;
            background-repeat: no-repeat;
          }
          @media screen and (min-width: 768px) {
            width: 50px;
            height: 40px;
            flex-basis: 50px;
          }
        }
      }
    }

    .list_expanded {
      font-size: 1em;
      display: none;
      margin: 5px 0 5px 0;
      padding: 0 0 0 20px;
      height: 0;
      &.expand {
        height: auto;
        display: block;
      }
      .list_more {
        color: #688194;
        text-decoration: underline;
      }
      @media screen and (min-width: 768px) {
        font-size: 1.4rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.8rem;
      }
    }
  }
}
.ar .partcontainer .list .list_item .list_handle .list_expand {
  transform: rotate(90deg);
  &.expanded {
    transform: rotate(0);
  }
}
@media screen and (max-height: 650px) {
  .ios10 .partcontainer .text {
    font-size: 1em;
  }
}
.ru .partcontainer h1 {
  font-size: 1.6em;
}
</style>
